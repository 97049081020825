<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-container fluid>
      <router-link to="/view-category">
        <b-button variant="primary" class="btn-tour-skip mb-2">
          <FeatherIcon icon="BookOpenIcon" />
          <span class="mx-2 align-middle">View Category</span>
        </b-button>
      </router-link>
      <b-row>
        <b-col>
          <b-card>
            <div class="ml-2">
              <b-button
                variant="primary"
                class="btn-tour-skip"
                @click="populatePostal('Add Postal', {})"
              >
                <FeatherIcon icon="PlusCircleIcon" />
                <span class="mx-2 align-middle">Add Postal</span>
              </b-button>
              <b-button
                variant="primary"
                class="btn-tour-skip ml-2"
                @click="getCoverages()"
              >
                <FeatherIcon icon="RepeatIcon" />
                <span class="mx-2 align-middle">Reload Table Data</span>
              </b-button>
            </div>
            <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
              <template v-slot="{ props }">
                <span v-if="props.column.field == 'Franchise_coverage'">
                  <b-button
                    block
                    :variant="props.row.Franchise_coverage ? 'primary' : 'danger'"
                  >
                    {{ props.row.Franchise_coverage }}
                  </b-button>
                </span>
                <span v-else-if="props.column.field == 'action'">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      @click="populatePostal('Edit Postal', props.row)"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="deleteCoverage(props.row)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </template>
            </MyTable>
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        id="postalModal"
        :title="modalTitle"
        hide-footer
        no-close-on-backdrop
        centered
        size="lg"
      >
        <ValidationObserver ref="postal">
          <b-row class="mb-2">
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="State"
                vid="state"
                rules="required"
              >
                <vSelect
                  v-model="postal.state"
                  :reduce="(option) => option.id"
                  :options="states"
                  placeholder="Select State"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Postcode"
                vid="postcode"
                rules="required|digits:5"
              >
                <input
                  v-model="postal.postcode"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Postcode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Area"
                vid="area"
                rules="required"
              >
                <input
                  v-model="postal.area"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Area"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <b-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Franchise"
                vid="Franchise"
                rules="required"
              >
                <vSelect
                  v-model="postal.FranchiseCoverage"
                  :reduce="(option) => option.id"
                  :options="Franchises"
                  :getOptionLabel="(option) => option.Franchise_name"
                  placeholder="Select Franchise Coverage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-button
                variant="gradient-primary"
                type="submit"
                @click="submitPostal()"
              >
                SUBMIT
              </b-button>
            </b-col>
          </b-row>
        </ValidationObserver>
      </b-modal>
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Processing... Please wait.</h3>
      </div>
    </template>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
export default {
  components: {
    MyTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      branches: "",
      rows: [],
      modalTitle: "",
      pageLength: 5,
      searchTerm: "",
      exportType: "",
      columns: [
        {
          label: "State",
          field: "state",
        },
        {
          label: "Area",
          field: "area",
        },
        {
          label: "Postcode",
          field: "postcode",
        },
        {
          label: "Franchise Coverage",
          field: "Franchise_coverage",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      Franchises: [],
      postal: {
        id: "",
        stateId: "",
        state: "",
        area: "",
        FranchiseCoverage: "",
        postcode: "",
      },
      isEditing: false,
      required,
    };
  },
  created() {
    this.isLoading = true;
    this.getBranches();
    this.getStates();
    this.getCoverages();
    this.getFranchisers();
    this.isLoading = false;
  },
  methods: {
    populatePostal(title, row) {
      this.modalTitle = title;
      this.postal.id = row.id || "";
      this.postal.state = row.state_id || "";
      this.postal.area = row.area || "";
      this.postal.FranchiseCoverage =
        this.findIdByFranchiseName(row.Franchise_coverage) || "";
      this.postal.postcode = row.postcode || "";
      this.isEditing = this.postal.id != "";
      this.$bvModal.show("postalModal");
    },
    findIdByFranchiseName(FranchiseName) {
      const Franchise = this.Franchises.find(
        (item) => item.Franchise_name === FranchiseName
      );
      return Franchise ? Franchise.id : null;
    },
    async getBranches() {
      const response = await this.$http.get("branch");

      if (response.data.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        this.isLoading = false;
        return;
      }

      this.branches = response.data.data.map((item) => ({
        ...item,
        label: item.area,
      }));
    },
    async getStates() {
      const response = await this.$http.get("state");
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        this.isLoading = false;
        return;
      }

      this.states = response.data.data.map((item) => ({
        ...item,
        label: item.state_name,
      }));
    },
    getDefaultToast() {
      return {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
    },
    async submitPostal() {
      this.isLoading = true;
      const isValid = await this.$refs.postal.validate();
      if (!isValid) {
        this.isLoading = false;
        return;
      }
      this.$bvModal.hide("postalModal");
      if (this.isEditing) {
        this.$http
          .put(`postal_price_checker/${this.postal.id}`, this.postal)
          .then((response) => {
            if (response.data.status == false) {
              for (const field in response.data.errors) {
                response.data.errors[field].forEach((message) => {
                  this.$toast.error(message, this.getDefaultToast());
                });
              }
            } else {
              this.$toast.success(
                response.data.message,
                this.getDefaultToast()
              );
            }
            this.getCoverages();
            this.postal = {};
            this.isEditing = "";
            this.isLoading = false;
          })
          .catch((error) => {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: error,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
          });
      } else {
        this.$http
          .post("postal_price_checker", this.postal)
          .then((response) => {
            if (response.data.status == false) {
              for (const field in response.data.errors) {
                response.data.errors[field].forEach((message) => {
                  this.$toast.error(message, this.getDefaultToast());
                });
              }
            } else {
              this.$toast.success(
                response.data.message,
                this.getDefaultToast()
              );
            }
            this.getCoverages();
            this.postal = {};
            this.isEditing = "";
            this.isLoading = false;
          })
          .catch((error) => {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: error,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
            console.log("error: " + error);
          });
      }
    },
    async getCoverages() {
      this.isLoading = true;
      const response = await this.$http.get("postal_core_list");
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.rows = response.data.data;
      this.isLoading = false;
    },
    async getFranchisers() {
      this.isLoading = true;
      const response = await this.$http.get("Franchises");
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.Franchises = response.data.data;
      this.isLoading = false;
    },
    deleteCoverage(row) {
      this.$swal({
        title: "Are you sure?",
        text: row.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.delete(
            `postal_price_checker/${row.id}`
          );
          if (response.data.status === true) {
            this.getCoverages();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/scss/style.scss";
</style>
